var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.src)?_c('div',{staticClass:"d-flex flex-column justify-center"},[(_vm.needLogin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"orange accent-2","bottom":"","left":"","fixed":"","dark":""},on:{"click":function($event){return _vm.setShowLoginDialog(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-alert")])],1)]}}],null,false,441691762)},[_c('span',[_vm._v(_vm._s(_vm.$t("pdf-viewer.limited-visible-pages1") + _vm.numPages + _vm.$t("pdf-viewer.limited-visible-pages2") + _vm.visiblePages + _vm.$t("pdf-viewer.limited-visible-pages3")))])]):_vm._e(),_c('div',{staticClass:"fullwidth"},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"dense":"","dark":"","tile":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.scale+=0.1; if (_vm.scale > 10) { _vm.scale = 10; }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")])],1)]}}],null,false,253310830)},[_c('span',[_vm._v(_vm._s(_vm.$t("pdf-viewer.zoom-in")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.scale-=0.1; if (_vm.scale < 0.1) { _vm.scale = 0.1; }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify-minus-outline")])],1)]}}],null,false,3018809628)},[_c('span',[_vm._v(_vm._s(_vm.$t("pdf-viewer.zoom-out")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.scale='page-width'}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-expand-all")])],1)]}}],null,false,3814766702)},[_c('span',[_vm._v(_vm._s(_vm.$t("pdf-viewer.page-width")))])]),_c('span',{staticClass:"pl-3 pr-3"},[_vm._v("|")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.rotate-=90}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)]}}],null,false,3489532034)},[_c('span',[_vm._v(_vm._s(_vm.$t("pdf-viewer.rotate-left")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.rotate+=90}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-right")])],1)]}}],null,false,569621407)},[_c('span',[_vm._v(_vm._s(_vm.$t("pdf-viewer.rotate-right")))])])],1)],1),(_vm.pdfData)?_c('div',{staticClass:"fullwidth auto-scroll-x pdf-container"},_vm._l((_vm.visiblePages),function(i){return _c('pdf',{key:i,attrs:{"src":_vm.pdfData,"resize":_vm.resize,"rotate":_vm.rotate,"scale":_vm.scale,"id":i,"page":i},on:{"update:scale":function($event){_vm.scale=$event},"error":function($event){return _vm.alert($event)}}},[_c('template',{slot:"loading"},[_c('div',{staticClass:"d-flex justify-center align-center loading"},[_c('v-img',{attrs:{"contain":"","alt":"loading content here...","max-width":"30","src":require("../assets/loading.gif")}})],1)])],2)}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }