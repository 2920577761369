import axios from "axios";
import * as baseRepo from "./BaseRepo";

export class GeolocationRepo extends baseRepo.BaseRepo {
  constructor() {
    super("https://app.yangben.pro/api/geolocation/");
  }

  async getChinaProvinces() {
    var data = {
      provinces: []
    };

    await axios
      .get(this.baseUrl + "provinces")
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.provinces) {
            response.data.provinces.forEach(p => {
              data.provinces.push({
                code: p.code,
                name: p.nameCN
              });
            });
          }
        }
      })
      .catch()
      .finally();

    return data;
  }

  async getChinaCities(province) {
    var data = {
      cities: []
    };

    await axios
      .get(this.baseUrl + "cities?provinceCode=" + province)
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.cities) {
            response.data.cities.forEach(p => {
              data.cities.push({
                code: p.code,
                name: p.nameCN
              });
            });
          }
        }
      })
      .catch()
      .finally();

    return data;
  }
}
