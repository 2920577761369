<template>
  <div v-if="src" class="d-flex flex-column justify-center">
    <v-tooltip top v-if="needLogin" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab color="orange accent-2" bottom left fixed dark v-on="on" v-bind="attrs" @click="setShowLoginDialog(true)">
          <v-icon>mdi-account-alert</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("pdf-viewer.limited-visible-pages1") + numPages + $t("pdf-viewer.limited-visible-pages2") + visiblePages + $t("pdf-viewer.limited-visible-pages3") }}</span>
    </v-tooltip>

    <div class="fullwidth">
      <v-card dense dark tile class="d-flex justify-center align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="scale+=0.1; if (scale > 10) { scale = 10; }">
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("pdf-viewer.zoom-in") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="scale-=0.1; if (scale < 0.1) { scale = 0.1; }">
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("pdf-viewer.zoom-out") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="scale='page-width'">
              <v-icon>mdi-arrow-expand-all</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("pdf-viewer.page-width") }}</span>
        </v-tooltip>

        <span class="pl-3 pr-3">|</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="rotate-=90">
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("pdf-viewer.rotate-left") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="rotate+=90">
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("pdf-viewer.rotate-right") }}</span>
        </v-tooltip>
      </v-card>
    </div>

    <div v-if="pdfData" class="fullwidth auto-scroll-x pdf-container">
      <pdf :src="pdfData" :resize="resize" :rotate="rotate" :scale.sync="scale" @error="alert($event)"
        v-for="i in visiblePages" :key="i" :id="i" :page="i">
        <template slot="loading">
          <div class="d-flex justify-center align-center loading">
            <v-img contain alt="loading content here..." max-width="30" src="../assets/loading.gif"></v-img>
          </div>
        </template>
      </pdf>
    </div>
  </div>
</template>

<script>
import PdfVuer from 'pdfvuer'
import { mapActions } from "vuex";

export default {
  name: "PdfViewer",
  props: ["src", "previewPages"],
  components: {
    pdf: PdfVuer
  },
  data: function() {
    return {
      authenticated: false,
      pdfData: undefined,
      numPages: 0,
      resize: false,
      page: 1,
      rotate: 0,
      scale: "page-width",
      visiblePages: 1
    };
  },
  computed: {
    needLogin: function() {
      if (!this.authenticated && this.numPages > this.previewPages) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions([
      "setPageTitle", "setShowLoginDialog", "setMessage", "getAccessToken"
    ]),
    getPdf() {
      this.pdfData = PdfVuer.createLoadingTask(this.src);
      this.pdfData.then(pdf => {
        this.numPages = pdf.numPages;
        if (!this.authenticated) {
          if (this.numPages > this.previewPages) {
            this.visiblePages = this.previewPages;
          }
          else {
            this.visiblePages = this.numPages;
          }
        }
        else {
          this.visiblePages = this.numPages;
        }
      });
    },
    async authenticate() {
      var token = await this.getAccessToken();
      if (token) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  watch: {
    async "$store.state.token"() {
      this.authenticate().then(auth => {
        this.authenticated = auth;
        if (this.authenticated) {
          this.visiblePages = this.numPages;
        }
      });
    }
  },
  mounted() {
    this.authenticate().then(auth => this.authenticated = auth);
    this.visiblePages = this.previewPages;
    this.getPdf();
  }
}
</script>

<style scoped>
.loading {
  min-height: 80px;
}
.pdf-container {
  background-color: lightgray;
}
</style>