import axios from "axios";
import qs from "qs";
import * as baseRepo from "./BaseRepo";

export class RegisterRepo extends baseRepo.BaseRepo {
  constructor() {
    super("https://testwww.yangben.pro/prod-api/");
  }

  async getCaptchaImage() {
    var endpoint = this.baseUrl + "captchaImage";

    var imgObj = null;
    await axios
      .get(endpoint, { })
      .then(resp => {
        if (resp.status == 200 && resp.data.img && resp.data.uuid) {
          imgObj = {
            uuid: resp.data.uuid,
            img: "data:image/gif;base64," + resp.data.img
          };
        }
      })
      .catch(() => {
        imgObj = null;
      })
      .finally();

    return imgObj;
  }

  async register(mobilePhone, captchaCode, uuid) {
    var endpoint = this.baseUrl + "yby/api/user/reg";

    var regObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          phonenumber: mobilePhone,
          code: captchaCode,
          uuid: uuid,

          password: "1234567"
        }),
        {
          headers: {
            // "Content-Type" : "application/json;charset=UTF-8"
          }
        }
      )
      .then(resp => {
        if (resp.status == 200 && resp.data.access_token) {
          regObj = {
            result: "succeeded",
            access_token: resp.data.access_token
          };
        }
        else if (resp.data.msg) {
          regObj = {
            result: "failed",
            msg: resp.data.msg
          };
        }
        else {
          regObj = {
            result: "exception"
          };
        }
      })
      .catch(() => {
        regObj = {
          result: "exception"
        };
      })
      .finally();

    return regObj;
  }

  async loginWithUsernamePassword(username, password, captchaCode, uuid) {
    var endpoint = this.baseUrl + "oauth/token";

    var loginObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          username: username,
          password: password,
          client_id: "mobileh5",
          client_secret: "u9qp0QOz",
          grant_type: "password",
          scope: "server",
          code: captchaCode,
          uuid: uuid
        })
      )
      .then(resp => {
        if (resp.status == 200) {
          if (resp.data.access_token) {
            loginObj = {
              result: "succeeded",
              access_token: resp.data.access_token
            };
          }
          else if (resp.data.msg) {
            loginObj = {
              result: "failed",
              msg: resp.data.msg
            };
          }
          else {
            loginObj = {
              result: "exception"
            };
          }
        }
      })
      .catch(() => {
        loginObj = {
          result: "exception"
        };
      })
      .finally();

    return loginObj;
  }

  async loginWithMobilephone(mobilePhone, captchaCode, uuid) {
    var endpoint = this.baseUrl + "oauth/token";

    var loginObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          phonenumber: mobilePhone,
          client_id: "phonenumber",
          client_secret: "I30oQV1L",
          grant_type: "password",
          scope: "server",
          code: captchaCode,
          uuid: uuid
        })
      )
      .then(resp => {
        if (resp.status == 200) {
          if (resp.data.access_token) {
            loginObj = {
              result: "succeeded",
              access_token: resp.data.access_token
            };
          }
          else if (resp.data.msg) {
            loginObj = {
              result: "failed",
              msg: resp.data.msg
            };
          }
          else {
            loginObj = {
              result: "exception"
            };
          }
        }
      })
      .catch(() => {
        loginObj = {
          result: "exception"
        };
      })
      .finally();

    return loginObj;
  }

  async getUserInfo(token) {
    if (!token) {
      return null;
    }

    var endpoint = this.baseUrl + "yby/apiouth/user/getInfo";
    var infoObj = null;
    await axios
      .get(endpoint, {
        headers: {
          "Authorization" : "bearer " + token
        }
      })
      .then(resp => {
        if (resp.status == 200 && resp.data.user) {
          infoObj = {
            user: resp.data.user
          };
        }
      })
      .catch(() => {
        infoObj = null;
      })
      .finally();

    return infoObj;
  }

  async updateUserInfo(token, username, name, email, company, job, cityCode) {
    var endpoint = this.baseUrl + "yby/apiouth/user/completeUserInfo";

    var userObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          userName: username,
          realname: name,
          email: email,
          company: company,
          position: job,
          addr2code: cityCode
        }),
        {
          headers: {
            "Authorization" : "bearer " + token
          }
        }
      )
      .then(resp => {
        if (resp.status == 200) {
          userObj = {
            result: "succeeded"
          };
        }
        else if (resp.data.msg) {
          userObj = {
            result: "failed",
            msg: resp.data.msg
          };
        }
        else {
          userObj = {
            result: "exception"
          };
        }
      })
      .catch(() => {
        userObj = {
          result: "exception"
        };
      })
      .finally();

    return userObj;
  }
}
