<template>
  <v-card flat tile class="d-flex flex-wrap align-center">
    <v-card-title>漾本云是什么？</v-card-title>
    <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</v-card-text>
    <v-card-title>提供什么服务？</v-card-title>
    <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</v-card-text>

    <v-flex class="pl-4 pr-4">
      <v-row>
        <v-col>
            <v-btn color="primary" elevation="0" width="100%" @click="$router.push('personal-info')">{{ $t("to-fulfill-personal-info.personal-info") }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
            <v-btn color="primary" elevation="0" width="100%">{{ $t("to-fulfill-personal-info.skip") }}</v-btn>
        </v-col>
      </v-row>
    </v-flex>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ToFullfillPersonalInfo",
  methods: {
    ...mapActions([
      "setPageTitle"
    ])
  },
  async created() {
    this.setPageTitle(this.$t("page-title.to-fulfill-personal-info"));
  }
}
</script>

<style scoped>
.v-card__title {
  margin: auto;
}
.v-card__text {
  text-align: start;
}
.v-application .primary {
    background-color: #118EE9 !important;
    border-color: #118EE9 !important;
}
</style>