<template>
  <v-carousel hide-delimiters :show-arrows="false" height="auto">
    <v-carousel-item>
      <v-sheet>
        <div class="d-flex flex-row banner">
          <div class="pl-10 d-flex align-center banner-text">升级漾本云会员服务，享受更多空间</div>
          <v-spacer></v-spacer>
          <div class="pr-2 d-flex align-center">
            <v-btn rounded elevation="0" height="21px" class="banner-button">每月199元</v-btn>
          </div>
        </div>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "PromoBanner"
}
</script>

<style scoped>
.banner {
  height: 40px;
  background-color: rgba(250, 205, 145);
}
.banner-text {
  color: #F59A23;
  font-size: 12px;
}
.banner-button {
  font-size: 11px;
  color: #7B4D12;
  background: linear-gradient(to right, rgba(250, 205, 145, 1), rgba(245, 154, 35, 1));
}
</style>