<template>
  <div class="d-flex align-center fullheight">
    <v-img contain src="../assets/logo.png"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Index",
  data: function() {
    return {
      accessToken: ""
    };
  },
  methods: {
    ...mapActions([
      "setShowLoginDialog", "setMessage"
    ]),
  },
  created() {
    // window.location.href = "https://testwww.yangben.pro";
  }
}
</script>
