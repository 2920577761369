import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import * as registerRepo from "../repository/RegisterRepo";

Vue.use(Vuex)
Vue.use(VueCookies)

export default new Vuex.Store({
  state: {
    pageTitle: "",
    message: "",
    errorMessage: "",

    showLoginDialog: false,
    token: null
  },
  getters: {
  },
  mutations: {
    updatePageTitle: (state, pageTitle) => {
      state.pageTitle = pageTitle;
    },
    updateMessage: (state, msg) => {
      if (msg.type == 1) {
        state.message = msg.content;
      } else {
        state.errorMessage = msg.content;
      }
    },
    updateShowLoginDialog: (state, showLoginDialog) => {
      state.showLoginDialog = showLoginDialog;
    },
    updateToken: (state, token) => {
      state.token = token;
    }
  },
  actions: {
    setPageTitle({ commit }, pageTitle) {
      commit("updatePageTitle", pageTitle);
    },
    setMessage({ commit }, msg) {
      commit("updateMessage", msg);
    },
    setShowLoginDialog({ commit }, showLoginDialog) {
      commit("updateShowLoginDialog", showLoginDialog);
    },
    setToken({ commit }, token) {
      commit("updateToken", token);
      Vue.$cookies.set("token", token, "1h");
    },
    async getAccessToken() {
      var token = Vue.$cookies.get("token");
      if (!token) {
        return null;
      }

      let regRepo = new registerRepo.RegisterRepo();
      var infoObj = await regRepo.getUserInfo(token);
      if (!infoObj || !infoObj.user) {
        return null;
      }

      return token;
    }
  },
  modules: {
  }
})
