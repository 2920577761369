import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/Index'
import Register from '../components/Register'
import ToFullfillPersonalInfo from '../components/ToFullfillPersonalInfo'
import PersonalInfo from '../components/PersonalInfo'
import Activity from '../components/Activity'
import Document from '../components/Document'
import User from '../components/User'
import Content from '../components/Content'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  // },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/register/to-personal-info',
    name: 'ToPersonalInfo',
    component: ToFullfillPersonalInfo
  },
  {
    path: '/register/personal-info',
    name: 'PersonalInfo',
    component: PersonalInfo
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/activity/:id',
    name: 'ActivityWithId',
    component: Activity,
    props: route => ({
      activityid: route.params.id,
    })
  },
  {
    path: '/document',
    name: 'Document',
    component: Document
  },
  {
    path: '/document/:id',
    name: 'DocumentWithId',
    component: Document,
    props: route => ({
      documentid: route.params.id,
      activityid: route.query.activityid
    })
  },
  {
    path: '/document/:id/content',
    name: 'ContentWithId',
    component: Content,
    props: route => ({
      documentid: route.params.id
    })
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/user/:id',
    name: 'UserWithId',
    component: User,
    props: route => ({
      userid: route.params.id,
      documentid: route.query.documentid,
    })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
