<template>
  <v-card flat tile>
    <div v-if="noDocument">
      <v-card-subtitle v-if="documentid" class="pa-0">
        <v-btn tile text color="#3178BD" @click="$router.push('/document/' + documentid)">&lt; {{ $t("content.back-to-document") }}</v-btn>
      </v-card-subtitle>
      <v-alert border="bottom" colored-border type="error" elevation="2" class="ma-10">
        {{ $t("content.error-msg.no-document") }}
      </v-alert>
    </div>
    <div v-else>
      <v-card v-if="document" flat tile class="ma-2">
        <v-card-title class="justify-center">{{ document.companyName }}</v-card-title>
        <v-btn tile text color="#3178BD" class="pa-0 text-tiny" @click="$router.push('/document/' + documentid)">&lt; {{ document.name }}</v-btn>
        <div class="d-flex flex-row">
          <v-img transition="scale-transition" max-width="40" :alt="document.companyName" :src="document.companyLogo"/>
          <div class="d-flex align-end">
            <v-card-subtitle class="pl-4 pt-0 pb-0">{{ document.owner }}</v-card-subtitle>
          </div>
        </div>

        <v-card-title class="justify-center">{{ document.name }}</v-card-title>
        <v-card-subtitle v-if="!authenticate()" class="justify-center align-center">
          <div class="text-center">{{ $t("content.preview-desc-1") + document.previewPages + $t("content.preview-desc-2") }}</div>
        </v-card-subtitle>

        <pdf :src="document.fileUri" :previewPages="document.previewPages"/>
        <!-- <iframe :src="document.fileUri" style="width: 100%; height: 800px" /> -->
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import * as supportingRepo from "../repository/SupportingRepo";
import PdfViewer from "./PdfViewer";

export default {
  name: "Content",
  props: ["documentid"],
  components: {
    pdf: PdfViewer
  },
  data: function() {
    return {
      noDocument: true,
      document: null
    };
  },
  methods: {
    ...mapActions([
      "setPageTitle", "setShowLoginDialog", "setMessage", "getAccessToken"
    ]),
    async authenticate() {
      var token = await this.getAccessToken();
      if (token) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  watch: {
    async "document"() {
      if (this.document) {
        this.setPageTitle(this.document.name);
      }
      else {
        this.setPageTitle("");
      }
    }
  },
  async created() {
    this.setPageTitle("");

    if (!this.documentid || this.documentid == "") {
      this.noDocument = true;
    }
    else {
      let supportRepo = new supportingRepo.SupportingRepo();
      var document = await supportRepo.getDocument(this.documentid);
      if (document) {
        this.document = document;

        this.noDocument = false;
      }
      else {
        this.noDocument = true;
      }
    }
  }
}
</script>

<style scoped>
.text-tiny {
  font-size: x-small;
  line-height: 0.8rem;
}
</style>