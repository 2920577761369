import axios from "axios";
import qs from "qs";
import * as baseRepo from "./BaseRepo";

export class NotificationRepo extends baseRepo.BaseRepo {
  constructor() {
    super("https://app.yangben.pro/api/notification/");
  }

  async sendSmsCode(moiblePhone, culture) {
    var data = {
      status: "unknown",
      expiry: 90
    };

    await axios.post(
      this.baseUrl + "sendSmsCode",
      qs.stringify({
        mobilePhone: moiblePhone
      }),
      {
        headers: {
          "Accept-Language" : culture
        }
      })
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0) {
            data.status = "code_sent";
            data.expiry = response.data.expiry
          }
        }
      })
      .catch()
      .finally();

    return data;
  }

  async verifySmsCode(moiblePhone, code) {
    var data = {
      isVerified: false
    };

    await axios.post(
      this.baseUrl + "verifySmsCode",
      qs.stringify({
        mobilePhone: moiblePhone,
        code: code
      }),
      {
        headers: {
        }
      })
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0) {
            data.isVerified = response.data.isVerified;
          } else {
            data.isVerified = response.data.isVerified;
            data.errorCode = response.data.errors[0].code;
          }
        }
      })
      .catch(error => {
        data.isVerified = error.response.data.isVerified;
        data.errorCode = error.response.data.errors[0].code;
      })
      .finally();

    return data;
  }
}
