<template>
  <v-card flat tile>
    <div v-if="noUser">
      <v-card-subtitle v-if="documentid" class="pa-0">
        <v-btn tile text color="#3178BD" @click="$router.push('/document/' + documentid)">&lt; {{ $t("user.back-to-document") }}</v-btn>
      </v-card-subtitle>
      <v-alert v-if="noUser" border="bottom" colored-border type="error" elevation="2" class="ma-10">
        {{ $t("user.error-msg.no-user") }}
      </v-alert>
    </div>
    <div v-else>
      <div class="gray-background">
        <v-card v-if="user" flat tile color="#DDDDDD" class="ma-2">
          <v-card-title class="justify-center">{{ user.companyName }}</v-card-title>
          <v-card-subtitle v-if="documentid" class="pa-0">
            <v-btn tile text color="#3178BD" class="pa-0 text-tiny" @click="$router.push('/document/' + documentid)">&lt; {{ $t("document.back-to-activity") }}</v-btn>
          </v-card-subtitle>
          <div class="d-flex flex-row">
            <v-avatar color="lightgrey">
              <v-img transition="scale-transition" :alt="user.personName" :src="user.avatar"></v-img>
            </v-avatar>
            <div class="d-flex flex-column">
              <v-card-subtitle class="pl-5 pt-0 pb-0 text-small">{{ user.personName }}</v-card-subtitle>
              <v-card-subtitle class="pl-5 pt-0 pb-0 text-small">
                <div class="d-flex flex-row">
                  <div v-if="user.position">{{ user.position }}.</div>
                  <div v-if="user.shortName">{{ user.shortName }}</div>
                </div>
              </v-card-subtitle>
              <v-card-subtitle v-if="user.userAddress" class="pl-5 pt-0 pb-0 text-small">
                {{ user.userAddress.province }}.{{ user.userAddress.city }}
              </v-card-subtitle>
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="watched" text outlined color="#118EE9" class="watch-button" @click="removeWatch()">{{ $t("user.remove-watch") }}</v-btn>
            <v-btn v-else text outlined color="#118EE9" class="watch-button" @click="addWatch()">{{ $t("user.add-watch") }}</v-btn>
          </div>
          <v-card-subtitle>
            {{ user.fansCount }} <span class="text--disabled">{{ $t("user.fans") }}</span> {{ user.idolsCount }} <span class="text--disabled">{{ $t("user.watch") }}</span>
          </v-card-subtitle>
          <v-card flat tile max-width="40">
            <v-img v-if="user.companyLogo" contain transition="scale-transition" :alt="$t('user.picture')" :src="user.companyLogo"></v-img>
          </v-card>
          <v-card-subtitle v-if="user.fullName" class="pl-1 pr-1 pt-0 pb-0">{{ user.fullName }}</v-card-subtitle>
          <v-card-subtitle v-if="user.companyAddress" class="pl-1 pr-1 pt-0 pb-0">
            {{ $t("user.address") }}<span v-if="user.companyAddress.province">{{ user.companyAddress.province }}</span> <span v-if="user.companyAddress.city">{{ user.companyAddress.city }}</span> <span v-if="user.companyAddress.extra">{{ user.companyAddress.extra }}</span>
          </v-card-subtitle>
          <div class="d-flex flex-row">
            <div><v-card-subtitle v-if="user.webSite" class="pl-1 pr-1 pt-0 pb-0">{{ $t("user.website") + user.webSite }}</v-card-subtitle></div>
            <v-spacer></v-spacer>
            <div><v-card-subtitle v-if="user.telephone" class="pl-1 pr-1 pt-0 pb-0">{{ $t("user.telephone") + user.telephone }}</v-card-subtitle></div>
          </div>
          <v-expansion-panels v-if="user.memo" flat v-model="showMore" class="mt-5 gray-background">
            <v-expansion-panel>
              <v-expansion-panel-content class="gray-background">
                <v-card-subtitle v-html="user.memo"></v-card-subtitle>
              </v-expansion-panel-content>
              <v-expansion-panel-header class="gray-background"><p class="d-flex justify-end align-center user-more">{{ moreText }}</p></v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </div>

      <Banner class="mt-n2"/>

      <v-card flat tile v-if="documents" class="ma-2">
        <v-card-title>{{ $t("user.related-documents") }} {{ user.documentCount }}</v-card-title>
        <v-list three-line class="pa-0">
          <template v-for="(doc, index) in documents">
            <v-list-item :key="doc.id" class="pl-1 pr-1" @click="$router.push('/document/' + doc.id)">
              <v-list-item-content class="pt-0 pb-0">
                <div class="pa-2 d-flex flex-row">
                  <v-card flat tile elevation="3" max-width="90">
                    <v-img contain transition="scale-transition" :alt="$t('user.document-picture')" :src="doc.pictureUri"></v-img>
                  </v-card>
                  <div class="ml-3">
                    <div class="d-flex flex-column fullheight">
                      <v-list-item-title class="wrap-text">{{ doc.name }}</v-list-item-title>
                      <div class="align-end">
                        <v-list-item-subtitle class="wrap-text">
                          {{ doc.format }} |
                          {{ $t("user.document-created-date") + " " + convertToLocaleDateString(doc.updateDate) }} |
                          {{ $t("user.document-size") + " " + doc.size }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="wrap-text">
                          {{ $t("user.document-browsed-times") + " XXX" }} |
                          {{ $t("user.document-downloaded-times") + " YYY" }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import * as supportingRepo from "../repository/SupportingRepo";
import Banner from "../components/PromoBanner";

export default {
  name: "User",
  props: ["userid", "documentid"],
  components: {
    Banner
  },
  data: function() {
    return {
      noUser: true,
      user: null,
      documents: [],
      watched: false,
      showMore: false,
      moreText: this.$t("user.show-more")
    };
  },
  methods: {
    ...mapActions([
      "setPageTitle", "setShowLoginDialog", "setMessage", "getAccessToken"
    ]),
    convertToLocaleDateString(dateString) {
      return new Date(dateString).toLocaleDateString("zh-CN", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    async authenticate() {
      var token = await this.getAccessToken();
      if (token) {
        return true;
      }
      else {
        this.setShowLoginDialog(true);
        return false;
      }
    },
    async hasWatched(needAuth) {
      if (needAuth) {
        if (!await this.authenticate()) {
          return;
        }
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var watchObj = await supportRepo.hasWatched(this.$cookies.get("token"), this.user.userName);
      if (watchObj && watchObj.result == "succeeded") {
        this.watched = watchObj.watched;
      }
      else {
        this.watched = false;
      }
    },
    async addWatch() {
      if (!await this.authenticate()) {
        return;
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var watchObj = await supportRepo.addWatch(this.$cookies.get("token"), this.user.userName);
      if (watchObj && watchObj.result == "succeeded") {
        this.setMessage({type: 1, content: this.$t("user.msg.add-watch-succeeded")});
        await this.hasWatched(false);
      }
      else {
        this.setMessage({type: 2, content: this.$t("user.error-msg.add-watch-failed")});
      }
    },
    async removeWatch() {
      if (!await this.authenticate()) {
        return;
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var watchObj = await supportRepo.removeWatch(this.$cookies.get("token"), this.user.userName);
      if (watchObj && watchObj.result == "succeeded") {
        this.setMessage({type: 1, content: this.$t("user.msg.remove-watch-succeeded")});
        await this.hasWatched(false);
      }
      else {
        this.setMessage({type: 2, content: this.$t("user.error-msg.remove-watch-failed")});
      }
    }
  },
  watch: {
    async "user"() {
      if (this.user) {
        this.setPageTitle(this.user.companyName);
      }
      else {
        this.setPageTitle("");
      }
    },
    async "showMore"(val) {
      if (val == 0) {
        this.moreText = this.$t("user.hide-less");
      }
      else {
        this.moreText = this.$t("user.show-more");
      }
    }
  },
  async created() {
    this.setPageTitle("");

    if (!this.userid || this.user == "") {
      this.noUser = true;
    }
    else {
      let supportRepo = new supportingRepo.SupportingRepo();
      var user = await supportRepo.getUser(this.userid);
      if (user) {
        this.user = user;

        this.noUser = false;
        this.hasWatched(true);

        var documents = await supportRepo.getUserDocuments(this.userid);
        if (documents && documents.length > 0) {
          this.documents = documents;
        }
      }
      else {
        this.noUser = true;
      }
    }
  }
}
</script>

<style scoped>
.text-tiny {
  font-size: x-small;
  line-height: 0.8rem;
}
.text-samll {
  font-size: small;
  line-height: 0.8rem;
}
.gray-background {
  background-color: #DDDDDD;
}
.watch-button {
  border-color: #118EE9 !important;
  border-width: 2px;
}
.user-more {
  margin: auto 0;
}
</style>