<template>
    <v-dialog v-if="showLoginDialog" v-model="showLoginDialog" persistent width="90%">
      <v-card>
        <v-tabs v-model="tab" dark icons-and-text background-color="#118EE9">
          <v-tab href="#username-password-tab">
            <v-icon>mdi-account-box</v-icon>
          </v-tab>

          <v-tab href="#mobilephone-tab">
            <v-icon>mdi-cellphone</v-icon>
          </v-tab>
        </v-tabs>

        <v-flex>
          <v-tabs-items v-model="tab">
            <v-tab-item class="pt-12 pl-3 pr-3" key="0" value="username-password-tab">
              <v-form ref="formUsernamePassword" v-model="isFormUsernamePasswordValid" @submit.prevent>
                <v-row>
                  <v-col class="user-input">
                    <div class="d-flex flex-row">
                      <div class="d-flex flex-column caption-column"><p>{{ $t("username.caption") }}</p></div>
                      <div class="d-flex flex-column text-field-column">
                        <v-text-field flat dense outlined clearable
                          v-model="username"
                          :rules="usernameRules"
                          :placeholder="$t('username.placeholder')"/>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="user-input">
                    <div class="d-flex flex-row">
                      <div class="d-flex flex-column caption-column"><p>{{ $t("password.caption") }}</p></div>
                      <div class="d-flex flex-column text-field-column">
                        <v-text-field flat dense outlined clearable
                          v-model="password"
                          :rules="passwordRules"
                          :placeholder="$t('password.placeholder')"/>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field flat dense outlined clearable
                      v-model="captchaCode"
                      :rules="captchaCodeRules"
                      :placeholder="$t('captcha.captcha-code.placeholder')"/>
                  </v-col>
                  <v-col>
                    <v-img v-if="this.captchaImage" id="captchaImage" contain height="100%" :src="captchaImage" @click="getCaptchaImage()"/>
                    <v-btn v-else outlined width="100%" @click="getCaptchaImage()">{{ $t("captcha.captcha-code.caption") }}</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>

            <v-tab-item class="pt-12 pl-3 pr-3" key="1" value="mobilephone-tab">
              <v-form ref="formMobilephone" v-model="isFormMobilephoneValid" @submit.prevent>
                <v-row>
                  <v-col class="user-input">
                    <div class="d-flex flex-row">
                      <div class="d-flex flex-column caption-column"><p>+86</p></div>
                      <div class="d-flex flex-column text-field-column">
                        <v-text-field flat dense outlined clearable counter="11" maxLength="11" v-mask="'###########'"
                          v-model="mobilePhone"
                          ref="mobilePhoneField"
                          :rules="mobilePhoneRules"
                          :placeholder="$t('mobile-phone.placeholder')"/>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field flat dense outlined clearable counter="6" maxLength="6" v-mask="'######'"
                      v-model="verificationCode"
                      :rules="verificationCodeRules"
                      :placeholder="$t('verification-code.placeholder')"/>
                  </v-col>
                  <v-col>
                    <v-btn outlined width="100%" @click="getSmsCode()">{{ $t("verification-code.caption") }}</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field flat dense outlined clearable
                      v-model="captchaCode"
                      :rules="captchaCodeRules"
                      :placeholder="$t('captcha.captcha-code.placeholder')"/>
                  </v-col>
                  <v-col>
                    <v-img v-if="this.captchaImage" id="captchaImage" contain height="100%" :src="captchaImage" @click="getCaptchaImage()"/>
                    <v-btn v-else outlined width="100%" @click="getCaptchaImage()">{{ $t("captcha.captcha-code.caption") }}</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
          </v-tabs-items>

          <div class="pl-3 pr-3">
            <v-row class="mt-3">
              <v-col class="mb-0">
                <v-btn color="primary" elevation="0" width="100%" @click="login()">{{ $t("login.submit") }}</v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="col-2 d-flex justify-start">
                <v-btn text class="term-link" @click="closeDialog()">{{ $t("login.cancel") }}</v-btn>
              </v-col>
              <v-col class="d-flex justify-end">
                <p class="question-text">{{ $t("login.question") }}</p>
                <v-btn text class="pa-0 term-link" @click="gotoRegister()">{{ $t("login.go-to-register") }}</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-flex>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import * as registerRepo from "../repository/RegisterRepo";
import * as notificationRepo from "../repository/NotificationRepo";

export default {
  directives: { mask },
  name: "Login",
  data: function() {
    return {
      tab: "username-password-tab",
      isFormUsernamePasswordValid: false,
      isFormMobilephoneValid: false,
      username: "",
      usernameRules: [
        v => !!v || this.$t("username.validation.required")
      ],
      password: "",
      passwordRules: [
        v => !!v || this.$t("password.validation.required")
      ],
      mobilePhone: "",
      mobilePhoneRules: [
        v => !!v || this.$t("mobile-phone.validation.required"),
        v => (v && v.length == 11) || this.$t("mobile-phone.validation.digits")
      ],
      verificationCode: "",
      verificationCodeRules: [
        v => !!v || this.$t("verification-code.validation.required"),
        v => (v && v.length == 6) || this.$t("verification-code.validation.digits")
      ],
      uuid: "",
      captchaImage: "",
      captchaCode: "",
      captchaCodeRules: [
        v => !!v || this.$t("captcha.captcha-code.validation.required")
      ],
    }
  },
  computed: {
    ...mapState([
      "showLoginDialog"
    ])
  },
  methods: {
    ...mapActions([
      "setShowLoginDialog", "setMessage", "setToken"
    ]),
    validate() {
      if (!this.captchaImage) {
        this.uuid = "";
        this.captchaCode = "";
      }

      switch (this.tab) {
        case "username-password-tab":
          this.isFormUsernamePasswordValid = this.$refs.formUsernamePassword.validate();
          break;
        case "mobilephone-tab":
          this.isFormMobilephoneValid = this.$refs.formMobilephone.validate();
          break;
        default:
          break;
      }
    },
    async getCaptchaImage() {
      let repo = new registerRepo.RegisterRepo();
      var imgObj = await repo.getCaptchaImage();
      if (imgObj) {
        this.uuid = imgObj.uuid;
        this.captchaImage = imgObj.img;
      } else {
        this.setMessage({ content: this.$t("captcha.error-msg.failed-to-get-captcha-img"), type: 2 });
      }
    },
    async getSmsCode() {
      if (!this.mobilePhone) {
        this.$refs.mobilePhoneField.$refs.input.focus();
        return;
      }

      let repo = new notificationRepo.NotificationRepo();
      var data = await repo.sendSmsCode(this.mobilePhone, this.$root.$i18n.locale);
      if (data.status == "code_sent") {
        var msg = this.$t("register.msg.code-sent");
        msg = msg.replace("{0}", data.expiry);
        this.setMessage({type: 1, content: msg});
      }
    },
    async closeDialog() {
      if (this.$refs.formUsernamePassword) {
        this.$refs.formUsernamePassword.reset();
      }
      if (this.$refs.formMobilephone) {
        this.$refs.formMobilephone.reset();
      }
      this.setShowLoginDialog(false);
    },
    gotoRegister() {
      this.closeDialog();
      var target = "/register";
      if (this.$route.path != target) {
        this.$router.push(target)
      }
    },
    async login() {
      this.validate();

      let regRepo = new registerRepo.RegisterRepo();
      let noteRepo = new notificationRepo.NotificationRepo();
      var loginObj = null;
      switch (this.tab) {
        case "username-password-tab":
          if (!this.isFormUsernamePasswordValid) {
            return;
          }

          loginObj = await regRepo.loginWithUsernamePassword(this.username, this.password, this.captchaCode, this.uuid);
          break;
        case "mobilephone-tab":
          if (!this.isFormMobilephoneValid) {
            return;
          }

          // verify SMS code first
          var verifyObj = await noteRepo.verifySmsCode(this.mobilePhone, this.verificationCode);
          if (!verifyObj.isVerified) {
            var msg = this.$t("login.error-msg.verify-sms-code-failed");
            switch (verifyObj.errorCode) {
              case 5:
                msg = this.$t("login.error-msg.verify-sms-code-not-match");
                break;
              case 6:
                msg = this.$t("login.error-msg.verify-sms-code-expired");
                break;
              case 7:
                msg = this.$t("login.error-msg.verify-sms-code-exceeded");
                break;
              default:
                break;
            }
            this.setMessage({type: 2, content: msg});
            return;
          }

          // call reg API to login then
          loginObj = await regRepo.loginWithMobilephone(this.mobilePhone, this.captchaCode, this.uuid);
          break;
        default:
          break;
      }

      if (loginObj) {
        switch (loginObj.result) {
          case "succeeded":
            if (loginObj.access_token) {
              this.setToken(loginObj.access_token);
              this.closeDialog();
            }
            break;
          case "failed":
            this.setMessage({type: 2, content: loginObj.msg});
            break;
          case "exception":
            this.setMessage({type: 2, content: this.$t("login.error-msg.system-error")});
            break;
          default:
            break;
        }
      }
    }
  },
  watch: {
    async "tab"() {
      switch (this.tab) {
        case "username-password-tab":
          this.$refs.formMobilephone.reset();
          break;
        case "mobilephone-tab":
          this.$refs.formUsernamePassword.reset();
          break;
        default:
          break;
      }
      this.getCaptchaImage();
    }
  },
  async created() {
    this.getCaptchaImage();
  }
}
</script>

<style scoped>
.col {
  height: 40px;
  padding: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 25px;;
}
.v-application .primary {
  background-color: #118EE9 !important;
  border-color: #118EE9 !important;
}
.term-link {
  color: #118EE9 !important;
}
.v-btn {
  height: 100% !important;
}
.user-input {
  border: #E4E4E4;
  border-style: solid;
  border-radius: 4px;
  border-width: thin;
  display: inline-block;
}
  .user-input .v-application .flex-row {
    height: 100%;
  }
  .user-input .caption-column {
    width: 100px;
  }
  .user-input .caption-column p {
    height: 40px;
    display: -webkit-flex; /* safari */
    display: flex;
    align-items: center;
    justify-items: start;
    padding: 0px 10px;
  }
  .user-input .text-field-column {
    width: 100%;
  }
.question-text {
  font-size: 0.875em;
  margin-top: 11px;
}
</style>