import axios from "axios";
import qs from "qs";
import * as baseRepo from "./BaseRepo";

export class SupportingRepo extends baseRepo.BaseRepo {
  constructor() {
    super("https://app.yangben.pro/api/supporting/");
  }

  async getCompanies() {
    var companies = [];

    await axios
      .get(this.baseUrl + "companies")
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.companies) {
            response.data.companies.forEach(c => {
              companies.push(c.name);
            });
          }
        }
      })
      .catch()
      .finally();

    return companies;
  }

  async getActivity(id) {
    if (!id) {
      return null;
    }

    var activity = null;

    await axios
      .get(this.baseUrl + "activity/" + id)
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.activity) {
            activity = response.data.activity;
          }
        }
      })
      .catch()
      .finally();

    return activity;
  }

  async getActivityDocuments(id) {
    if (!id) {
      return null;
    }

    var documents = null;

    await axios
      .get(this.baseUrl + "activity/" + id + "/documents")
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.documents && response.data.documents.length > 0) {
            documents = [];
            response.data.documents.forEach(doc => {
              documents.push(doc);
            });
          }
        }
      })
      .catch()
      .finally();

    return documents;
  }

  async getDocument(id) {
    if (!id) {
      return null;
    }

    var document = null;

    await axios
      .get(this.baseUrl + "document/" + id)
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.document) {
            document = response.data.document;
          }
        }
      })
      .catch()
      .finally();

    return document;
  }

  async getDocumentContent(id) {
    if (!id) {
      return null;
    }

    var document = null;

    await axios
      .get(this.baseUrl + "document/" + id)
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.document) {
            document = response.data.document;
          }
        }
      })
      .catch()
      .finally();

    return document;
  }

  async hasWatched(token, watched) {
    var watchObj = null;
    await axios
      .get(this.baseUrl + "user/" + watched + "/watched",
        {
          headers: {
            // "Content-Type": "application/x-www-form-urlencoded",
            authorization: token
          }
        }
      )
      .then(resp => {
        if (resp.status == 200) {
          watchObj = {
            result: "succeeded",
            watched: resp.data.watched
          };
        }
        else {
          watchObj = {
            result: "failed",
            watched: false
          };
        }
      })
      .catch(() => {
        watchObj = {
          result: "exception",
          watched: false
        };
      })
      .finally();

    return watchObj;
  }

  async addWatch(token, watched) {
    var endpoint = this.baseUrl + "user/addWatch";

    var watchObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          idol: watched
        }),
        {
          headers: {
            // "Content-Type" : "application/json;charset=UTF-8",
            "authorization": token
          }
        }
      )
      .then(resp => {
        if (resp.status == 200) {
          watchObj = {
            result: "succeeded"
          };
        }
        else {
          watchObj = {
            result: "failed"
          };
        }
      })
      .catch(() => {
        watchObj = {
          result: "exception"
        };
      })
      .finally();

    return watchObj;
  }

  async removeWatch(token, watched) {
    var endpoint = this.baseUrl + "user/removeWatch";

    var watchObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          idol: watched
        }),
        {
          headers: {
            // "Content-Type" : "application/json;charset=UTF-8",
            "authorization": token
          }
        }
      )
      .then(resp => {
        if (resp.status == 200) {
          watchObj = {
            result: "succeeded"
          };
        }
        else {
          watchObj = {
            result: "failed"
          };
        }
      })
      .catch(() => {
        watchObj = {
          result: "exception"
        };
      })
      .finally();

    return watchObj;
  }

  async hasFavored(token, object) {
    var favoredObj = null;
    await axios
      .get(this.baseUrl + "user/" + object + "/favored",
        {
          headers: {
            authorization: token
          }
        }
      )
      .then(resp => {
        if (resp.status == 200) {
          favoredObj = {
            result: "succeeded",
            watched: resp.data.favored
          };
        }
        else {
          favoredObj = {
            result: "failed",
            watched: false
          };
        }
      })
      .catch(() => {
        favoredObj = {
          result: "exception",
          watched: false
        };
      })
      .finally();

    return favoredObj;
  }

  async addFavorite(token, object) {
    var endpoint = this.baseUrl + "user/addFavorite";

    var favorObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          obj: object
        }),
        {
          headers: {
            "authorization": token
          }
        }
      )
      .then(resp => {
        if (resp.status == 200) {
          favorObj = {
            result: "succeeded"
          };
        }
        else {
          favorObj = {
            result: "failed"
          };
        }
      })
      .catch(() => {
        favorObj = {
          result: "exception"
        };
      })
      .finally();

    return favorObj;
  }

  async removeFavorite(token, object) {
    var endpoint = this.baseUrl + "user/removeFavorite";

    var favorObj = null;
    await axios
      .post(endpoint,
        qs.stringify({
          obj: object
        }),
        {
          headers: {
            "authorization": token
          }
        }
      )
      .then(resp => {
        if (resp.status == 200) {
          favorObj = {
            result: "succeeded"
          };
        }
        else {
          favorObj = {
            result: "failed"
          };
        }
      })
      .catch(() => {
        favorObj = {
          result: "exception"
        };
      })
      .finally();

    return favorObj;
  }

  async getUser(id) {
    if (!id) {
      return null;
    }

    var user = null;

    await axios
      .get(this.baseUrl + "user/" + id)
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.user) {
            user = response.data.user;
          }
        }
      })
      .catch()
      .finally();

    return user;
  }

  async getUserDocuments(id) {
    if (!id) {
      return null;
    }

    var documents = null;

    await axios
      .get(this.baseUrl + "user/" + id + "/documents")
      .then(response => {
        if (response != null) {
          if (response.data.responseCode == 0 && response.data.documents && response.data.documents.length > 0) {
            documents = [];
            response.data.documents.forEach(doc => {
              documents.push(doc);
            });
          }
        }
      })
      .catch()
      .finally();

    return documents;
  }

  async getDocumentData(uri) {
    if (!uri) {
      return null;
    }

    var document = null;

    await axios
      .get(this.baseUrl + "document/content?path=" + uri)
      .then(response => {
        if (response != null) {
          if (response.data) {
            document = response.data;
          }
        }
      })
      .catch()
      .finally();

    return document;
  }
}
