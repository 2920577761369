<template>
  <v-card flat tile>
    <v-card-title class="justify-center">仅需1分钟，让我们更了解您</v-card-title>

    <v-form id="form" ref="form" v-model="isFormValid" @submit.prevent>
      <v-flex>
        <v-row>
          <v-col class="user-input">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column caption-column"><p>{{ $t('personal-info.name.caption') }}</p></div>
              <div class="d-flex flex-column text-field-column">
                <v-text-field flat dense outlined clearable
                  v-model="name"
                  :rules="nameRules"
                  :placeholder="$t('personal-info.name.placeholder')"/>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="user-input">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column caption-column"><p>{{ $t('personal-info.email.caption') }}</p></div>
              <div class="d-flex flex-column text-field-column">
                <v-text-field flat dense outlined clearable
                  v-model="email"
                  :rules="emailRules"
                  :placeholder="$t('personal-info.email.placeholder')"/>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="user-input">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column caption-column"><p>{{ $t('personal-info.company.caption') }}</p></div>
              <div class="d-flex flex-column text-field-column">
                <v-combobox flat dense outlined clearable auto-select-first cache-items hide-no-data
                  v-model="company"
                  :rules="companyRules"
                  :loading="isLoadingCompanies"
                  :items="companies"
                  item-text="name"
                  :no-data-text="$t('personal-info.company.no-data')"
                  :placeholder="$t('personal-info.company.placeholder')"
                  :search-input.sync="searchCompany">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>Search for your favorite <strong>Cryptocurrency</strong></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-combobox>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="user-input">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column caption-column"><p>{{ $t('personal-info.job-title.caption') }}</p></div>
              <div class="d-flex flex-column text-field-column">
                <v-text-field flat dense outlined clearable
                  v-model="jobTitle"
                  :rules="jobTitleRules"
                  :placeholder="$t('personal-info.job-title.placeholder')"/>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column select-field-caption"><p>{{ $t('personal-info.address.caption') }}</p></div>
              <div class="d-flex flex-column pl-9">
                <v-select outlined dense class="d-flex justify-start"
                  :label="$t('personal-info.address.province.caption')"
                  :no-data-text="$t('personal-info.address.province.no-data')"
                  :items="provinces"
                  :rules="provinceRules"
                  v-model="province"
                  item-text="name" item-value="code"/>
              </div>
              <div class="d-flex flex-column pl-1">
                <v-select outlined dense
                  :label="$t('personal-info.address.city.caption')"
                  :no-data-text="$t('personal-info.address.city.no-data')"
                  :items="cities"
                  :rules="cityRules"
                  v-model="city"
                  item-text="name" item-value="code"/>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col><v-btn color="primary" elevation="0" width="100%" @click="updateUser()">{{ $t("personal-info.submit") }}</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0"><v-btn text color="primary" width="100%" @click="register()">{{ $t("personal-info.skip") }}</v-btn></v-col>
        </v-row>
      </v-flex>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import * as registerRepo from "../repository/RegisterRepo";
import * as geolocationRepo from "../repository/GeolocationRepo";

export default {
  name: "PersonalInfo",
  data: function() {
    return {
      loginnedUser: null,
      isFormValid: true,
      company: "",
      companyRules: [
        v => !!v || this.$t("personal-info.company.validation.required")
      ],
      isLoadingCompanies: false,
      companies: [],
      searchCompany: null,
      jobTitle: "",
      jobTitleRules: [
        v => !!v || this.$t("personal-info.job-title.validation.required")
      ],
      name: "",
      nameRules: [
        v => !!v || this.$t("personal-info.name.validation.required")
      ],
      email: "",
      emailRules: [
        v => !!v || this.$t("personal-info.email.validation.required"),
        v => (v && /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(v)) || this.$t("personal-info.email.validation.invalid_email")
      ],
      provinces: [],
      province: null,
      provinceRules: [
        v => !!v || this.$t("personal-info.address.province.validation.required")
      ],
      cities: [],
      city: null,
      cityRules: [
        v => !!v || this.$t("personal-info.address.city.validation.required")
      ]
    };
  },
  methods: {
    ...mapActions([
      "setPageTitle", "setMessage", "setShowLoginDialog"
    ]),
    validate() {
      this.isFormValid = this.$refs.form.validate();
    },
    async authenticate() {
      var token = await this.getAccessToken();
      if (token) {
        return true;
      }
      else {
        this.setShowLoginDialog(true);
        return false;
      }
    },
    async updateUser() {
      this.validate();
      if (!this.isFormValid) {
        return;
      }

      var logined = await this.authenticate();
      if (!logined) {
        return;
      }

      var token = this.$cookies.get("token");
      let regRepo = new registerRepo.RegisterRepo();
      if (this.loginnedUser) {
        var userObj = await regRepo.updateUserInfo(token, this.loginnedUser.userName, this.name, this.email, this.company, this.jobTitle, this.city);
        if (userObj) {
          switch (userObj.result) {
            case "succeeded":
              this.setMessage({type: 1, content: this.$t("personal-info.msg.user-updated")});
              break;
            case "failed":
              this.setMessage({type: 2, content: userObj.msg});
              break;
            case "exception":
              this.setMessage({type: 2, content: this.$t("personal-info.error-msg.system-error")});
              break;
          }
        }
      }
    }
  },
  watch: {
    async "province"() {
      let repo = new geolocationRepo.GeolocationRepo();
      var dataObj = await repo.getChinaCities(this.province);
      if (dataObj) {
        this.cities = dataObj.cities;
      } else {
        this.cities = [];
      }
    },
    searchCompany() {
      // Items have already been loaded
      if (this.companies.length > 0) return;

      this.isLoadingCompanies = true;

      // Lazily load input items
      fetch('https://app.yangben.pro/api/supporting/companies')
        .then(response => response.clone().json())
        .then(response => {
          this.companies = response.companies;
        })
        .catch(error => {
          this.companies = [];
          console.log(error);
        })
        .finally(() => (this.isLoadingCompanies = false))
    }
  },
  async created() {
    this.setPageTitle(this.$t("page-title.personal-info"));

    let geoRepo = new geolocationRepo.GeolocationRepo();
    var geoObj = await geoRepo.getChinaProvinces();
    if (geoObj) {
      this.provinces = geoObj.provinces;
    } else {
      this.provinces = [];
    }
  }
}
</script>

<style scoped>
.v-card__title {
  margin: 0 auto;
}
#form {
  width: 100%;
  padding: 0 20px;
}
#form {
  width: 100%;
  padding: 0 20px;
}
.col {
  height: 40px;
  padding: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 25px;;
}
.v-application .primary {
  background-color: #118EE9 !important;
  border-color: #118EE9 !important;
}
.user-input {
  border: #E4E4E4;
  border-style: solid;
  border-radius: 4px;
  border-width: thin;
  display: inline-block;
}
  .user-input .v-application .flex-row {
    height: 100%;
  }
  .user-input .caption-column {
    width: 100px;
  }
  .user-input .caption-column p {
    height: 40px;
    display: -webkit-flex; /* safari */
    display: flex;
    align-items: center;
    justify-items: start;
    padding: 0px 10px;
  }
  .user-input .text-field-column {
    width: 100%;
  }
.select-field-caption {
  min-width: 50px;
  padding-top: 8px;
  padding-left: 10px;
}
</style>
