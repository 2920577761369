import { render, staticRenderFns } from "./PromoBanner.vue?vue&type=template&id=65fc4c71&scoped=true&"
import script from "./PromoBanner.vue?vue&type=script&lang=js&"
export * from "./PromoBanner.vue?vue&type=script&lang=js&"
import style0 from "./PromoBanner.vue?vue&type=style&index=0&id=65fc4c71&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65fc4c71",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VSheet,VSpacer})
