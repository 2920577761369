import axios from "axios";
//axios.defaults.timeout = 10000;

export class BaseRepo {
  constructor(baseUrl) {
    if (baseUrl) {
      this.baseUrl = baseUrl
    } else {
      this.baseUrl = "";
    }
  }

  async logTracking(id) {
    var endpoint = this.baseUrl + "tracking";
    var idAdded = false;
    if (id) {
      endpoint = endpoint + "?id=" + id;
      idAdded = true;
    }
    var token = localStorage.getItem("uaes.documentsWeb.accessToken");
    if (token) {
      if (idAdded) {
        endpoint = endpoint + "&accessToken=" + token;
      }
      else {
        endpoint = endpoint + "?accessToken=" + token;
      }
    }

    await axios
      .get(endpoint)
      .then(() => { })
      .catch(() => { })
      .finally();
  }
}
