<template>
  <v-app>
    <v-app-bar flat height="35" color="white">
      <template v-slot:extension>
        <hr class="header-divider"/>
      </template>

      <v-row class="ma-0 pa-0">
        <v-col cols="2" style="height: 35px"></v-col>
        <v-col cols="8" style="height: 35px" class="d-flex align-center justify-center pa-0">
          <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
        </v-col>
        <v-col cols="2" style="height: 35px" class="d-flex align-center justify-end pa-0">
          <v-menu left bottom offset-y rounded="0" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn tile icon color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-icon>mdi-account-outline</v-icon>&nbsp;{{ $t("menu.personal-center") }}
                </v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item>
                <v-list-item-title>
                  <a class="menu-item" href="https://support.qq.com/products/314081/faqs-more" target="_blank"><v-icon>mdi-help-circle-outline</v-icon>&nbsp;{{ $t("menu.help") }}</a>
                </v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item>
                <v-list-item-title>
                  <a class="menu-item" href="https://support.qq.com/products/314081" target="_blank"><v-icon>mdi-pencil-box-outline</v-icon>&nbsp;{{ $t("menu.feedback") }}</a>
                </v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item v-if="!showLogoutMenuItem" @click="setShowLoginDialog">
                <v-list-item-title>
                  <v-icon>mdi-login-variant</v-icon>&nbsp;{{ $t("menu.login") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else @click="logout">
                <v-list-item-title>
                  <v-icon>mdi-logout-variant</v-icon>&nbsp;{{ $t("menu.logout") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath"/>
    </v-main>

    <v-footer class="d-flex align-center" color="white">
      <!-- <div class="fullheight">
        <router-link to="/">Home</router-link> |
        <router-link to="/register">Register</router-link> |
        <router-link to="/register/to-personal-info">To Personal Info</router-link> |
        <router-link to="/activity/2021011322241486541196f15e53e41c9a965769847d00dfc">Activity</router-link>
      </div> -->
      <p>{{ $t("footer.note") }}</p>
    </v-footer>

    <v-snackbar top v-model="showMessage" color="primary" :timeout="snackbarTimeout">
      <v-row>
        <v-col class="col-11 pa-2">{{ $store.state.message }}</v-col>
        <v-col class="col-1 pa-2 d-flex justify-end"><v-btn text class="pa-0" color="#118EE9" width="auto" @click="hideMessage()">X</v-btn></v-col>
      </v-row>
    </v-snackbar>
    <v-snackbar top v-model="showErrorMessage" color="error" :timeout="snackbarTimeout">
      <v-row>
        <v-col class="col-11 pa-2">{{ $store.state.errorMessage }}</v-col>
        <v-col class="col-1 pa-2 d-flex justify-end"><v-btn text class="pa-0" color="#118EE9" width="auto" @click="hideErrorMessage()">X</v-btn></v-col>
      </v-row>
    </v-snackbar>

    <Login/>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Login from "./components/Login";

export default {
  name: 'App',
  components: {
    Login
  },
  data: function() {
    return {
      snackbarTimeout: 5000,
      showMessage: false,
      messageTimer: null,
      showErrorMessage: false,
      errorMessageTimer: null,

      showLogoutMenuItem: false
    };
  },
  computed: {
    ...mapState([
      "pageTitle", "message", "errorMessage"
    ])
  },
  methods: {
    ...mapActions([
      "getAccessToken", "setShowLoginDialog", "setMessage", "setToken"
    ]),
    hideMessage() {
      this.showMessage = false;
      this.$store.state.message = "";
    },
    hideErrorMessage() {
      this.showErrorMessage = false;
      this.$store.state.errorMessage = "";
    },
    async authenticate() {
      var token = await this.getAccessToken();
      if (token == null) {
        return false;
      }

      if (token.length == 0) {
        return false;
      }

      return true;
    },
    logout() {
      this.setToken(null);
      this.setMessage({type: 1, content: this.$t("logout.msg")});
    }
  },
  watch: {
    async "$store.state.message"() {
      if (this.$store.state.message) {
        this.showMessage = true;
        this.messageTimer = setTimeout(() => {
          this.hideMessage();
        }, this.snackbarTimeout);
      }
    },
    async "$store.state.errorMessage"() {
      if (this.$store.state.errorMessage) {
        this.showErrorMessage = true;
        this.errorMessageTimer = setTimeout(() => {
          this.hideErrorMessage();
        }, this.snackbarTimeout);
      }
    },
    async "$store.state.token"() {
      if (this.$store.state.token && this.$store.state.token.length > 0) {
        this.showLogoutMenuItem = true;
      }
      else {
        this.showLogoutMenuItem = false;
      }
    }
  },
  async created() {
    if (await this.authenticate()) {
      this.showLogoutMenuItem = true;
    }
    else {
      this.showLogoutMenuItem = false;
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #118EE9 !important;
}
header {
  position: fixed !important;
  top: 0px;
  z-index: 1000;
  background-color: white;
  height: auto !important;
}
.v-toolbar__content {
  padding: 4px 0 !important;
  justify-content: end;
}
.v-toolbar__extension {
  height: 1px !important;
  padding: 0 !important;
}
.header-divider {
  width: 100%;
  height: 1px;
  border: none;
  background: #AEAEAE;
  margin: 0;
}
main {
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 15px 0 !important;
}
footer {
  height: 35px;
  width: 100%;
  color: #AEAEAE !important;
  font-size: 14px;
  justify-content: center;
  position: fixed !important;
  bottom: 0px;
  z-index: 1000;
}
.v-snack .v-btn {
  height: auto !important;
  min-width: 0px !important;
}
.v-snack__wrapper {
  width: 80%;
}
.v-snack__content {
  padding: 8px !important;
}

.v-text-field--outlined fieldset {
  border-color: #E4E4E4 !important;
}
.v-text-field--outlined.v-input--has-state fieldset {
  // border-color: #FF5252 !important;
  border-width: thin !important;
}
.user-input .v-text-field fieldset {
  border: none !important;
}
#captchaImage > .v-image__image {
  background-position: left center !important;
}
.fullheight {
  height: 100%;
}
.fullwidth {
  width: 100%;
}
.auto-scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.auto-scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}
.menu-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none;
}
</style>
