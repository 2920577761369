<template>
  <v-card flat tile class="d-flex flex-wrap align-center">
    <div id="logo">
      <v-img width="50%" src="../assets/logo.png"/>
      {{ $t("register.slogan") }}
    </div>

    <v-form id="form" ref="form" v-model="isFormValid" @submit.prevent>
      <v-flex>
        <v-row>
          <v-col class="user-input">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column caption-column"><p>+86</p></div>
              <div class="d-flex flex-column text-field-column">
                <v-text-field flat dense outlined clearable
                  counter="11" maxLength="11" v-mask="'###########'"
                  v-model="mobilePhone"
                  :rules="mobilePhoneRules"
                  :placeholder="$t('mobile-phone.placeholder')"/>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field flat dense outlined clearable
              counter="6" maxLength="6" v-mask="'######'"
              v-model="verificationCode"
              :rules="verificationCodeRules"
              :placeholder="$t('verification-code.placeholder')"/>
          </v-col>
          <v-col>
            <v-btn outlined width="100%" @click="getSmsCode()">{{ $t("verification-code.caption") }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field flat dense outlined clearable
              v-model="captchaCode"
              :rules="captchaCodeRules"
              :placeholder="$t('captcha.captcha-code.placeholder')"/>
          </v-col>
          <v-col>
            <v-img v-if="this.captchaImage" id="captchaImage" contain height="100%" :src="captchaImage" @click="getCaptchaImage()"/>
            <v-btn v-else outlined width="100%" @click="getCaptchaImage()">{{ $t("captcha.captcha-code.caption") }}</v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <v-btn color="primary" elevation="0" width="100%" @click="register()">{{ $t("register.submit") }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            {{ $t("register.term.caption") }}<v-btn text class="pa-0 term-link">{{ $t("register.term.link") }}</v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import * as registerRepo from "../repository/RegisterRepo";
import * as notificationRepo from "../repository/NotificationRepo";

export default {
  directives: { mask },
  name: "Register",
  data: function() {
    return {
      isFormValid: true,
      mobilePhone: "",
      mobilePhoneRules: [
        v => !!v || this.$t("mobile-phone.validation.required"),
        v => (v && v.length == 11) || this.$t("mobile-phone.validation.digits")
      ],
      verificationCode: "",
      verificationCodeRules: [
        v => !!v || this.$t("verification-code.validation.required"),
        v => (v && v.length == 6) || this.$t("verification-code.validation.digits")
      ],
      uuid: "",
      captchaImage: "",
      captchaCode: "",
      captchaCodeRules: [
        v => !!v || this.$t("captcha.captcha-code.validation.required"),
      ],
    };
  },
  methods: {
    ...mapActions([
      "setPageTitle", "setMessage", "setToken"
    ]),
    validate() {
      if (!this.captchaImage) {
        this.uuid = "";
        this.captchaCode = "";
      }

      this.isFormValid = this.$refs.form.validate();
    },
    async getCaptchaImage() {
      let repo = new registerRepo.RegisterRepo();
      var imgObj = await repo.getCaptchaImage();
      if (imgObj) {
        this.uuid = imgObj.uuid;
        this.captchaImage = imgObj.img;
      } else {
        this.setMessage({ content: this.$t("register.error-msg.failed-to-get-captcha-img"), type: 2 });
      }
    },
    async getSmsCode() {
      if (!this.mobilePhone) {
        return;
      }

      let repo = new notificationRepo.NotificationRepo();
      var data = await repo.sendSmsCode(this.mobilePhone, this.$root.$i18n.locale);
      if (data.status == "code_sent") {
        var msg = this.$t("register.msg.code-sent");
        msg = msg.replace("{0}", data.expiry);
        this.setMessage({type: 1, content: msg});
      }
    },
    async register() {
      this.validate();
      if (!this.isFormValid) {
        return;
      }

      // verify SMS code first
      let noteRepo = new notificationRepo.NotificationRepo();
      var verifyObj = await noteRepo.verifySmsCode(this.mobilePhone, this.verificationCode);
      if (!verifyObj.isVerified) {
        var msg = this.$t("register.error-msg.verify-sms-code-failed");
        switch (verifyObj.errorCode) {
          case 5:
            msg = this.$t("register.error-msg.verify-sms-code-not-match");
            break;
          case 6:
            msg = this.$t("register.error-msg.verify-sms-code-expired");
            break;
          case 7:
            msg = this.$t("register.error-msg.verify-sms-code-exceeded");
            break;
          default:
            break;
        }
        this.setMessage({type: 2, content: msg});
        return;
      }

      // call reg API then
      let regRepo = new registerRepo.RegisterRepo();
      var regObj = await regRepo.register(this.mobilePhone, this.captchaCode, this.uuid);
      if (regObj) {
        switch (regObj.result) {
          case "succeeded":
            if (regObj.access_token) {
              this.setToken(regObj.access_token);
              this.$router.push("/register/personal-info");
            }
            break;
          case "failed":
            this.setMessage({type: 2, content: regObj.msg});
            break;
          case "exception":
            this.setMessage({type: 2, content: this.$t("register.error-msg.system-error")});
            break;
          default:
            break;
        }
      }
    }
  },
  async created() {
    this.setPageTitle(this.$t("page-title.register"));
    this.getCaptchaImage();
  }
}
</script>

<style scoped>
#logo {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
}
  #logo > .v-image {
    margin: auto;
  }
#form {
  width: 100%;
  padding: 0 20px;
}
.col {
  height: 40px;
  padding: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 25px;;
}
.v-application .primary {
  background-color: #118EE9 !important;
  border-color: #118EE9 !important;
}
.term-link {
  color: #118EE9;
}
.v-btn {
  height: 100% !important;
}
.user-input {
  border: #E4E4E4;
  border-style: solid;
  border-radius: 4px;
  border-width: thin;
  display: inline-block;
}
  .user-input .v-application .flex-row {
    height: 100%;
  }
  .user-input .caption-column {
    width: 100px;
  }
  .user-input .caption-column p {
    height: 40px;
    display: -webkit-flex; /* safari */
    display: flex;
    align-items: center;
    justify-items: start;
    padding: 0px 10px;
  }
  .user-input .text-field-column {
    width: 100%;
  }
</style>