<template>
  <v-card flat tile>
    <div v-if="noDocument">
      <v-card-subtitle v-if="activityid" class="pa-0">
        <v-btn tile text color="#3178BD" @click="$router.push('/activity/' + activityid)">&lt; {{ $t("document.back-to-activity") }}</v-btn>
      </v-card-subtitle>
      <v-alert border="bottom" colored-border type="error" elevation="2" class="ma-10">
        {{ $t("document.error-msg.no-document") }}
      </v-alert>
    </div>
    <div v-else>
      <v-card v-if="document" flat tile class="ma-2">
        <v-card-title class="justify-center">{{ document.companyName }}</v-card-title>
        <v-card-subtitle v-if="activity" class="pa-0">
          <v-btn tile text color="#3178BD" class="pa-0 text-tiny" @click="$router.push('/activity/' + activity.id)">&lt; {{ activity.name }}</v-btn>
        </v-card-subtitle>
        <div class="d-flex flex-row">
          <div>
            <v-card flat tile elevation="3" max-width="180">
              <v-hover v-slot="{ hover }">
                <v-img contain transition="scale-transition" :alt="$t('document.picture')" :src="document.pictureUri" @click="$router.push('/document/' + document.id + '/content')">
                  <v-expand-transition>
                    <div v-if="hover" class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal display-3">
                      <v-card-subtitle class="white--text">{{ $t("document.read-more") }}</v-card-subtitle>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-hover>
            </v-card>
          </div>
          <div class="d-flex flex-column pl-6">
            <div class="align-center">
              <v-avatar color="lightgrey">
                <v-img :alt="document.creator" :src="document.creatorAvatar"></v-img>
              </v-avatar>
              <v-card-subtitle class="pl-0 pt-1 pb-1">{{ document.creator }}</v-card-subtitle>
            </div>
            <div class="d-flex flex-row">
              <a v-if="watched == true" color="#3178BD" class="pa-0 text-caption" @click="removeWatch()">{{ $t("document.remove-watch") }}</a>
              <a v-else color="#3178BD" class="pa-0 text-caption" @click="addWatch()">{{ $t("document.add-watch") }}</a>
              <v-spacer></v-spacer>
              <a color="#3178BD" class="pa-0 text-caption" @click="$router.push('/user/' + document.userId + '?documentid=' + document.id)">{{ $t("document.her-page") }}</a>
            </div>
            <v-card-subtitle class="pa-0 mt-4">
              <div class="d-flex flex-row text-tiny">
                <p class="ma-0">{{ $t("document.format") }}</p>
                <p class="ma-0">{{ document.format }}</p>
              </div>
              <div class="d-flex flex-row text-tiny">
                <p class="ma-0">{{ $t("document.created-date") }}</p>
                <p class="ma-0">{{ convertToLocaleDateString(document.updateDate) }}</p>
              </div>
              <div class="d-flex flex-row text-tiny">
                <p class="ma-0">{{ $t("document.size") }}</p>
                <p class="ma-0">{{ document.size }}</p>
              </div>
              <div class="d-flex flex-row text-tiny">
                <p class="ma-0">{{ $t("document.browsed-times") }}</p>
                <p class="ma-0">XXX</p>
              </div>
              <div class="d-flex flex-row text-tiny">
                <p class="ma-0">{{ $t("document.downloaded-times") }}</p>
                <p class="ma-0">YYY</p>
              </div>
              <div class="d-flex flex-row text-tiny">
                <p class="ma-0">{{ $t("document.favorated-times") }}</p>
                <p class="ma-0">ZZZ</p>
              </div>
            </v-card-subtitle>
            <v-spacer></v-spacer>
            <div class="d-flex flex-row text-caption">
              <a color="#3178BD" class="pa-0 text-caption" @click="download()">{{ $t("document.download") }}</a>
              <v-spacer></v-spacer>
              <p class="ma-0">|</p>
              <v-spacer></v-spacer>
              <a v-if="favored" color="#3178BD" class="pa-0 text-caption" @click="removeFavorite()">{{ $t("document.remove-favorite") }}</a>
              <a v-else color="#3178BD" class="pa-0 text-caption" @click="addFavorite()">{{ $t("document.add-favorite") }}</a>
              <v-spacer></v-spacer>
              <p class="ma-0">|</p>
              <v-spacer></v-spacer>
              <a color="#3178BD" class="pa-0 text-caption" @click="forward()">{{ $t("document.forward") }}</a>
            </div>
          </div>
        </div>
        <v-card-title class="justify-center">{{ document.name }}</v-card-title>
        <v-card-text>{{ $t("document.brief") }}</v-card-text>
        <v-card-subtitle class="pt-0" v-html="document.brief"></v-card-subtitle>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import * as supportingRepo from "../repository/SupportingRepo";

export default {
  name: "Document",
  props: ["documentid", "activityid"],
  data: function() {
    return {
      noDocument: true,
      document: null,
      activity: null,
      watched: false,
      favored: false
    };
  },
  methods: {
    ...mapActions([
      "setPageTitle", "setShowLoginDialog", "setMessage", "getAccessToken"
    ]),
    convertToLocaleDateString(dateString) {
      return new Date(dateString).toLocaleDateString("zh-CN", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    async authenticate() {
      var token = await this.getAccessToken();
      if (token) {
        return true;
      }
      else {
        this.setShowLoginDialog(true);
        return false;
      }
    },
    async hasWatched(needAuth) {
      if (needAuth) {
        if (!await this.authenticate()) {
          return;
        }
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var watchObj = await supportRepo.hasWatched(this.$cookies.get("token"), this.document.userName);
      if (watchObj && watchObj.result == "succeeded") {
        this.watched = watchObj.watched;
      }
      else {
        this.watched = false;
      }
    },
    async addWatch() {
      if (!await this.authenticate()) {
        return;
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var watchObj = await supportRepo.addWatch(this.$cookies.get("token"), this.document.userName);
      if (watchObj && watchObj.result == "succeeded") {
        this.setMessage({type: 1, content: this.$t("document.msg.add-watch-succeeded")});
        await this.hasWatched(false);
      }
      else {
        this.setMessage({type: 2, content: this.$t("document.error-msg.add-watch-failed")});
      }
    },
    async removeWatch() {
      if (!await this.authenticate()) {
        return;
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var watchObj = await supportRepo.removeWatch(this.$cookies.get("token"), this.document.userName);
      if (watchObj && watchObj.result == "succeeded") {
        this.setMessage({type: 1, content: this.$t("document.msg.remove-watch-succeeded")});
        await this.hasWatched(false);
      }
      else {
        this.setMessage({type: 2, content: this.$t("document.error-msg.remove-watch-failed")});
      }
    },
    async download() {
      if (!await this.authenticate()) {
        return;
      }
    },
    async hasFavored(needAuth) {
      if (needAuth) {
        if (!await this.authenticate()) {
          return;
        }
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var watchObj = await supportRepo.hasFavored(this.$cookies.get("token"), this.document.id);
      if (watchObj && watchObj.result == "succeeded") {
        this.favored = watchObj.watched;
      }
      else {
        this.favored = false;
      }
    },
    async addFavorite() {
      if (!await this.authenticate()) {
        return;
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var favorObj = await supportRepo.addFavorite(this.$cookies.get("token"), this.document.id);
      if (favorObj && favorObj.result == "succeeded") {
        this.setMessage({type: 1, content: this.$t("document.msg.add-favorite-succeeded")});
        await this.hasFavored(false);
      }
      else {
        this.setMessage({type: 2, content: this.$t("document.error-msg.add-favorite-failed")});
      }
    },
    async removeFavorite() {
      if (!await this.authenticate()) {
        return;
      }

      let supportRepo = new supportingRepo.SupportingRepo();
      var favorObj = await supportRepo.removeFavorite(this.$cookies.get("token"), this.document.id);
      if (favorObj && favorObj.result == "succeeded") {
        this.setMessage({type: 1, content: this.$t("document.msg.remove-favorite-succeeded")});
        await this.hasFavored(false);
      }
      else {
        this.setMessage({type: 2, content: this.$t("document.error-msg.remove-favorite-failed")});
      }
    },
    async forward() {
      if (!await this.authenticate()) {
        return;
      }
    }
  },
  watch: {
    async "activity"() {
      if (this.activity) {
        this.setPageTitle(this.activity.name);
      }
      else {
        this.setPageTitle("");
      }
    }
  },
  async created() {
    this.setPageTitle("");

    if (this.activityid) {
      let supportRepo = new supportingRepo.SupportingRepo();
      var activity = await supportRepo.getActivity(this.activityid);
      if (activity) {
        this.activity = activity;
      }
    }

    if (!this.documentid || this.documentid == "") {
      this.noDocument = true;
    }
    else {
      let supportRepo = new supportingRepo.SupportingRepo();
      var document = await supportRepo.getDocument(this.documentid);
      if (document) {
        this.document = document;

        this.noDocument = false;
        this.hasWatched(true);
        this.hasFavored(false);
      }
      else {
        this.noDocument = true;
      }
    }
  }
}
</script>

<style scoped>
.text-tiny {
  font-size: x-small;
  line-height: 0.8rem;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  background-color: rgba(64, 64, 64, 0.5);
  height: 15%;
}
</style>