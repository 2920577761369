<template>
  <v-card id="activity" flat tile>
    <v-alert v-if="noActivity" border="bottom" colored-border type="error" elevation="2" class="ma-10">
      {{ $t("activity.error-msg.no-activity") }}
    </v-alert>
    <div v-else>
      <v-card flat tile v-if="activity" class="ma-2">
        <v-card-title class="d-flex justify-center">{{ activity.companyName }}</v-card-title>
        <v-card-title class="d-flex justify-center">{{ activity.name }}</v-card-title>
        <v-card-subtitle class="d-flex justify-center">{{ convertToLocaleDateString(activity.startDate) }} - {{ convertToLocaleDateString(activity.endDate) }}</v-card-subtitle>
        <v-card-subtitle class="d-flex justify-center">{{ activity.address }}</v-card-subtitle>
        <v-card-text class="d-flex justify-center">
          <v-chip label color="rgba(230, 247, 255, 1)" text-color="rgba(145, 213, 255, 1)">{{ activity.venue }}</v-chip>
        </v-card-text>
      </v-card>

      <hr class="saperator-thick"/>

      <v-card flat tile v-if="activity" class="ma-2">
        <v-img contain max-height="60" :src="activity.pictureUri" transition="scale-transition" class="ml-4 activity-logo"/>
        <v-card-title>{{ $t("activity.about-activity") }}</v-card-title>
        <v-card-text v-html="activity.content"></v-card-text>
        <v-expansion-panels flat v-model="showMore">
          <v-expansion-panel>
            <v-expansion-panel-content class="pa-0">
              <v-card-title>{{ $t("activity.activity-info") }}</v-card-title>
              <v-row>
                <v-col class="pt-0 pb-0 pr-0 col-4 col-sm-2">
                  <v-card-subtitle class="pt-0 pb-0 pr-0">{{ $t("activity.activity-date") }}</v-card-subtitle>
                </v-col>
                <v-col class="pt-0 pb-0 pl-0 col-8 col-sm-10">
                  <v-card-subtitle class="pt-0 pb-0 pl-0">{{ convertToLocaleDateString(activity.startDate) }} - {{ convertToLocaleDateString(activity.endDate) }}</v-card-subtitle>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0 pr-0 col-4 col-sm-2">
                  <v-card-subtitle class="pt-0 pb-0 pr-0">{{ $t("activity.activity-address") }}</v-card-subtitle>
                </v-col>
                <v-col class="pt-0 pb-0 pl-0 col-8 col-sm-10">
                  <v-card-subtitle class="pt-0 pb-0 pl-0">{{ activity.address }}</v-card-subtitle>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0 pr-0 col-4 col-sm-2">
                  <v-card-subtitle class="pt-0 pb-0 pr-0">{{ $t("activity.activity-venue") }}</v-card-subtitle>
                </v-col>
                <v-col class="pt-0 pb-0 pl-0 col-8 col-sm-10">
                  <v-card-subtitle class="pt-0 pb-0 pl-0">{{ activity.venue }}</v-card-subtitle>
                </v-col>
              </v-row>

              <v-card-title>{{ $t("activity.activity-sponsor-info") }}</v-card-title>
              <v-card-subtitle>{{ activity.fullName }}</v-card-subtitle>
              <v-img contain max-height="60" :src="activity.companyLogo" transition="scale-transition" class="ml-4 activity-logo"/>
            </v-expansion-panel-content>
            <v-expansion-panel-header><p class="d-flex justify-end align-center activity-more">{{ moreText }}</p></v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>

      <hr class="saperator-thin"/>

      <v-card flat tile v-if="documents" class="ma-2">
        <v-card-title>{{ $t("activity.related-documents") }}</v-card-title>
        <v-list three-line class="pa-0">
          <template v-for="(doc, index) in documents">
            <v-list-item :key="doc.id" class="pl-1 pr-1" @click="$router.push('/document/' + doc.id + '?activityid=' + activity.id)">
              <v-list-item-content class="pt-0 pb-0">
                <div class="pa-2 d-flex flex-row">
                  <v-card flat tile elevation="3" max-width="90">
                    <v-img contain transition="scale-transition" :alt="$t('activity.document-picture')" :src="doc.pictureUri"></v-img>
                  </v-card>
                  <div class="ml-3">
                    <div class="d-flex flex-column fullheight">
                      <v-list-item-title class="wrap-text">{{ doc.name }}</v-list-item-title>
                      <div class="align-end">
                        <v-list-item-subtitle class="wrap-text">
                          {{ doc.format }} |
                          {{ $t("activity.document-created-date") + " " + convertToLocaleDateString(doc.updateDate) }} |
                          {{ $t("activity.document-size") + " " + doc.size }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="wrap-text">
                          {{ $t("activity.document-browsed-times") + " XXX" }} |
                          {{ $t("activity.document-downloaded-times") + " YYY" }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import * as supportingRepo from "../repository/SupportingRepo";

export default {
  name: "Activity",
  props: ["activityid"],
  data: function() {
    return {
      noActivity: false,
      activity: null,
      documents: [],
      showMore: null,
      moreText: this.$t("activity.show-more")
    };
  },
  methods: {
    ...mapActions([
      "setPageTitle"
    ]),
    convertToLocaleDateString(dateString) {
      return new Date(dateString).toLocaleDateString("zh-CN", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    }
  },
  watch: {
    async "activity"() {
      if (this.activity) {
        this.setPageTitle(this.activity.name);
      }

    },
    async "showMore"(val) {
      if (val == 0) {
        this.moreText = this.$t("activity.hide-less");
      }
      else {
        this.moreText = this.$t("activity.show-more");
      }
    }
  },
  async created() {
    if (!this.activityid || this.activityid == "") {
      this.noActivity = true;
    }
    else {
      let supportRepo = new supportingRepo.SupportingRepo();
      var activity = await supportRepo.getActivity(this.activityid);
      if (activity) {
        this.activity = activity;

        this.noActivity = false;

        var documents = await supportRepo.getActivityDocuments(this.activityid);
        if (documents && documents.length > 0) {
          this.documents = documents;
        }
      }
      else {
        this.noActivity = true;
      }
    }
  }
}
</script>

<style scoped>
.v-chip--label {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: rgba(145, 213, 255, 1) !important;
}
.saperator-thick {
 height: 5px;
 border: none;
 background-color: lightgray;
}
.saperator-thin {
 height: 1px;
 border: none;
 background-color: lightgray;
}
.activity-more {
  margin: auto 0;
}
.wrap-text {
  width: 100%;
  white-space: normal;
  word-wrap: break-word !important;
  word-break: break-all;
  -webkit-line-clamp: unset !important;
}
</style>

<style>
.activity-logo > .v-image__image--contain {
  background-position: left center !important;
}
#activity .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>